<template>

    <div class="main-full-content">
        <div class="f-p-b-15" style="display: flex">
            <el-button @click="addFee" type="primary" size="medium" :disabled="order.orderStatus==5" v-if="order.payLock === '0'">新增一行
            </el-button>
            <el-button @click="copyFee" type="primary" size="medium">复制订单费用</el-button>
            <el-button @click="lockBatch" type="primary" size="medium" :disabled="selectedRowKeys2.length===0 ">
                批量锁定
            </el-button>
            <el-button @click="unlockBatch" type="primary" size="medium" :disabled="selectedRowKeys2.length===0 " v-if="buttonShowList.batchUnlockFee">
                批量解锁
            </el-button>
            <el-button @click="deleteBatch" type="primary" size="medium" :disabled="selectedRowKeys2.length===0 ">
                批量删除
            </el-button>
            <el-button @click="updateBatch" type="primary" size="medium">保存
            </el-button>
            <el-button @click="addAttach" type="primary" size="medium" :disabled="isUse">添加附属费用
            </el-button>
          <el-button
              type="primary"
              size="medium"
              @click="downloadModel(url.downloadHyYfModel, '快递应付批量导入模板下载')"

          >快递应付批量导入模板下载
          </el-button>
          <el-upload
              action="/"
              :show-file-list="false"
              :http-request="uploadSectionFile"
              accept=".xls,.xlsx"
              style="margin:0 10px;display:inline"
          >
            <el-button size="medium" type="primary"
                       :loading="btnLoading"

            >批量快递应付导入
            </el-button>
          </el-upload>
              <el-button @click="lockOrder" type="primary" size="medium"
                   v-if="order.payLock==='0'"
        >锁定订单应付
        </el-button>
        <el-button @click="unlockOrder" type="primary" size="medium"
                   v-if="order.payLock==='1'"
                :disabled = "order.salesmanCommission =='1'">
          解锁订单应付
        </el-button>

        </div>
        <el-dialog title="复制订单" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="copy" ref="copy" :rules="rules">
                <el-form-item label="订单号" label-width="140px" prop="orderNo">
                    <el-input v-model="copy.orderNo" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="copySubmit">确定</el-button>
            </div>
        </el-dialog>



        <el-form :model="form" ref="payref" :rules="rules">
            <el-table
                    :header-cell-style="{ 'text-align': 'center' }"
                    ref="goodtab"
                    :data="form.osOrderSettleList"
                    style="width: 99%"
                    class="customTable"
                    v-loading="goodsloading"
                    @selection-change="handleSelectionChange2"
                    @sort-change="handleTableChange"
                    :cell-class-name="changeCellStyle">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="action" label="操作" minWidth="100">
                    <template slot-scope="scope">

                             <div v-if="order.payLock !== '1' || scope.row.isAttach ==='1'">
                              <el-button type="text" @click="removeSettle(scope.$index)"
                                         :disabled="scope.row.locker=='1' || (order.orderStatus==5 && scope.row.isAttach !='1')
                                         || ['1','2','9'].indexOf(scope.row.status) !== -1">删除
                              </el-button>
                              <el-button type="text" @click="unlock(scope.row)"
                                         :disabled="scope.row.locker ==='0'" v-if="buttonShowList.SingleUnlockFee">解锁
                              </el-button>
                              <el-button type="text" @click="lock(scope.row)" :disabled="scope.row.locker ==='1'">
                                  锁定
                              </el-button>

                              <el-button type="text" @click="updateSettleAccountStatus(scope.row,'1')"
                                         v-if="buttonShowList.gl && scope.row.isCloseAccount =='0'"
                              >
                                  关账
                              </el-button>
                              <el-button type="text" @click="updateSettleAccountStatus(scope.row,'0')"
                                         v-if="buttonShowList.jcgz&& scope.row.isCloseAccount =='1' "
                              >
                                  解除关账
                              </el-button>
                          </div>
                          <div v-if="order.payLock === '1' && scope.row.isAttach !=='1'" >
                            <span style="color:red;">已锁定</span>
                          </div>

                    </template>
                </el-table-column>
                <el-table-column prop="locker" label="锁定状态" minWidth="70">
                    <template slot-scope="scope">
                        <!--          <el-button type="text" @click="saveShip(scope.row)">保存</el-button>-->
                        {{getTag(scope.row.locker)}}
                    </template>
                </el-table-column>
                <el-table-column prop="settlement" label="结算对象" minWidth="180" v-if="buttonShowList['查看结算对象']">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.settleId'"
                                      :rules="rules.settlement">
                            <el-select v-el-select-loadmore="loadmore" :loading="cploading" @click.native="initProduct"
                                       clearable
                                       filterable v-model="scope.row.settleId" :disabled="scope.row.locker==='1' || (order.orderStatus==5 && scope.row.isAttach !='1')"
                                       @change="getSettle(scope)"
                            >
                                <el-option :label="item.chinaseName"
                                           :value="item.id" v-for="(item,index) in prolist"
                                           :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="feename" label="费用中文名" minWidth="180">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.feename'" :rules="rules.feename">
                          <el-row :gutter="scope.row.feename == '关税预收' ? 2 : 0">
                            <el-col :span="scope.row.feename == '关税预收' ? 8 : 24">
                              <el-select
                                      :loading="coloading"
                                      v-el-select-loadmore="loadmore2"
                                      clearable
                                      filterable
                                      class="f-full-width"
                                      v-model="scope.row.feename"
                                      placeholder="请选择费用"
                                      @click.native="initBdFeeTypeList(scope.row)"
                                      @change="updateFeeType(scope.row,scope.$index)"
                                      :disabled="scope.row.locker==='1' || (order.orderStatus==5 && scope.row.isAttach !='1')">
                                  <el-option
                                          v-for="(item,index) in feeTypeList"
                                          :key="index"
                                          :label="item.chineseName"
                                          :value="item.chineseName">
                                  </el-option>
                              </el-select>
                            </el-col>
                            <el-col :span="16" v-if="scope.row.feename == '关税预收'">
                              <f-select filterable
                                clearable
                                class="input"
                                :ref="'formulaSelect' + scope.$index"
                                v-model="scope.row.formula"
                                :isNeed="scope.row.formula"
                                dict="tariff_advance"
                                placeholder="请选择"
                                :disabled="scope.row.locker==='1' || (order.orderStatus==5 && scope.row.isAttach !='1')"
                                @click.native="getOldValue(scope.row)"
                                @changet="updateTariffAdvance(scope.row,scope.$index)"></f-select>
                            </el-col>
                          </el-row>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="feenameUs" label="费用英文名" minWidth="180">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.feenameUs'">
                            <span>{{scope.row.feenameUs}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="价格" minWidth="85">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.price'" :rules="rules.price">
                            <el-input v-model="scope.row.price" type="number" @change="getSummaries2"
                                      :disabled="scope.row.locker==='1' || (order.orderStatus==5 && scope.row.isAttach !='1')"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="quantity" label="数量" minWidth="85">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.quantity'" :rules="rules.qty">
                            <el-input v-model="scope.row.quantity" type="number" @change="getSummaries2"
                                      :disabled="scope.row.locker==='1' || (order.orderStatus==5 && scope.row.isAttach !='1')"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="moneyType" label="交易币种" min-width="110">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.moneyType'"
                                      :rules="rules.moneyType">
                            <f-select
                                    v-model="scope.row.moneyType"
                                    :isNeed="scope.row.moneyType"
                                    type="number" :dict="'bd_cur'"
                                    @change="getSummaries2" :disabled="scope.row.locker==='1' || (order.orderStatus==5 && scope.row.isAttach !='1')"></f-select>
                        </el-form-item>
                    </template>

                </el-table-column>
                <el-table-column prop="total" label="合计" minWidth="85">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.total'">
                            {{getAllPrice(scope.row)}}
                            <el-input v-model="scope.row.total" type="number" disabled></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" minWidth="150">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.remark'">
                            <el-input v-model="scope.row.remark" maxlength="250" autosize show-word-limit type="textarea"
                                      style="padding: 0 48px 0 0" :disabled="scope.row.locker==='1' || (order.orderStatus==5 && scope.row.isAttach !='1')"
                            ></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="createBy" label="录入人" minWidth="90" show-tooltip-when-overflow>
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.createBy'">
                            <span>{{scope.row.createBy}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="录入时间" minWidth="100" show-tooltip-when-overflow>
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.createTime'">
                            {{formateTime(scope.row.createTime)}}
                            <!--              <el-input v-model="scope.row.createTime"  disabled></el-input>-->
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="锁单时间"  show-tooltip-when-overflow>
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.lockTime'">
                            {{formateTime(scope.row.lockTime)}}
                            <!--              <el-input v-model="scope.row.lockTime"  disabled></el-input>-->
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="realMoneyType" label="折合币种" minWidth="240">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.realMoneyType'">
                            <span>{{scope.row.realMoneyType}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="exchangeRate" label="汇率" minWidth="120">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.exchangeRate'">
                            <span v-text="scope.row.exchangeRate"></span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="exchangeTotal" label="折合金额" minWidth="75">
                    <template slot-scope="scope">
                        <el-form-item >
                            <span v-text="scope.row.exchangeTotal"></span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="moneyWay" label="收款方式" minWidth="100">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.moneyWay'">
                            <span v-text="scope.row.moneyWay"></span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="销账状态" minWidth="80">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.status'">
                            <span v-if="scope.row.status === '0'">未销账</span>
                            <span v-if="scope.row.status === '1'">已销账</span>
                            <span v-if="scope.row.status === '2'">部分销账</span>
                            <span v-if="scope.row.status === '9'">付款申请中</span>

                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="realPay" label="已销账金额" minWidth="75">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.realPay'">
                            <span v-text="scope.row.realPay"></span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="xzCode" label="销账编码" minWidth="100">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.xzCode'">
                            <span v-text="scope.row.xzCode"></span>

                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="fkCode" label="付款编码" minWidth="100">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.fkCode'">
                            <span v-text="scope.row.fkCode"></span>

                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="fkTime" label="付款时间" minWidth="80">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.fkTime'">
                            <span>{{formateTime(scope.row.fkTime)}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="xzTime" label="销账时间" minWidth="80">
                    <template slot-scope="scope">
                        <el-form-item :prop="'osOrderSettleList.' + scope.$index + '.xzTime'">
                            <span>{{formateTime(scope.row.xzTime)}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>
            <p>{{ss}}</p>
          <p>统计（折合CNY）：应收：{{recTotal}}，应付：{{payTotal}}，利润：{{profit}}</p>
        </el-form>
        <el-divider></el-divider>
        <fee-log-module ref="fee" @ok="init" :type="'1'"></fee-log-module>
    </div>
</template>

<script>
import {deleteAction, getAction, getAllSupplier, getBdFeeTypeList, postAction, putAction} from '@/api/manage'
    import FeeLogModule from "./FeeLogModule";
    import QueryMixins from "@/mixins/QueryMixins.js"
    import {validateCtn, validateUnitValue} from "@/utils/validator";

    export default {
        name: 'PayModule',
        mixins: [QueryMixins],
        components: {
            FeeLogModule
        },
        props: {
            isUse: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                allSettlelist: [],
                allFeeTypelist: [],
                order: {},
                btnLoading: false,
                formData2: {
                    pageIndex: 1,
                    pageSize: 100
                },
                //GBP
                totalgbp: 0,
                // SGD
                totalsgd: 0,
                // USD
                totalusd: '',
                // NCD
                totalncd: '',
                // AUD
                totalaud: '',
                // GBP

                // VND
                totalvnd: '',
                // NZD
                totalnzd: '',
                // HKD
                totalhkd: '',
                // CAD
                totalcad: '',
                // EUR
                totaleur: '',
                // INR
                totalinr: '',
                // CNY
                totalcny: '',
                // KRW
                totalkrw: '',
                // NOK
                totalnok: '',
                // JPY
                totaljpy: '',
                // NZD
                ss: '',
                // TWD
                totaltwd: '',

                visible: false,
                row: {},
                feeTypeList: [],
                feeTypeMap: {},
                form: {osOrderSettleList: []},
                url: {
                    updateSettleAccountStatus: '/order/os/settle/updateSettleAccountStatus',
                    getSettleList: '/order/kdOrderSettle/list',
                    copy: '/order/kdOrderSettle/copyOrderFee',
                    delete: '/order/kdOrderSettle/delete',
                    deleteBatch: '/order/kdOrderSettle/deleteBatch',
                    lock: '/order/kdOrderSettle/lock',
                    unlock: '/order/kdOrderSettle/unlock',
                    lockBatch: '/order/kdOrderSettle/lockBatch',
                    lockOrder: '/order/kdOrderSettle/lockOrder',
                    settlelist: '/order/kdOrderSettle/out/settle',
                    unlockBatch: '/order/kdOrderSettle/unlockBatch',
                    unlockOrder: '/order/kdOrderSettle/unlockOrder',
                    updateBatch: '/order/kdOrderSettle/updateBatch',
                    getByOrderNo: '/order/kdOrder/getByOrderNo/',
                    importHySettles: "/order/kdOrderSettle/importKdSettles",
                    downloadHyYfModel: '/order/os/settle/Hy/in/downloadKdYfExcel',
                    unlockKdOrder:"/order/kdOrderSettle/unlockKdOrder"   //解锁
                },
                userlist: [],
                fbaloading: false,
                storeloading: false,
                warehouseList: [],
                fbalist: [],
                addresslist: [],
                addressloading: false,
                goodsloading: false,
                selectedRowKeys2: [],
                selectionRows2: [],
                //校验
                rules: {
                    settlement: [
                        {required: true, message: '请选择结算对象', trigger: 'change'}
                    ],
                    feename: [
                        {required: true, message: '请选择费用中文名', trigger: 'change'}
                    ],
                    price: [
                        {required: true, message: '请输入价格', trigger: 'blur'},
                        // {validator: validateUnitValue, trigger: 'change'},
                    ],
                    qty: [
                        {required: true, message: '请填写数量', trigger: 'blur'},
                        {validator: validateCtn, trigger: 'change'},
                    ],
                    moneyType: [
                        {required: true, message: '请选择币种', trigger: 'change'},

                    ],
                    orderNo: [
                        {required: true, message: '请输入订单号', trigger: 'blur'},
                    ],

                },
                copy: {},
                dialogFormVisible: false,
                isouri: false,
                cploading: false,
                proMap: {},
                prolist: [],
                coloading: false,
                oldFeename: '', //点击每一个费用中文名的时候保存的原先的值
                oldFormula: '', //保存关税预收原先的计算公式
              recTotal:'',
              payTotal:'',
              profit:'',
            }
        },
        methods: {
            // 更新订单费用的关账状态
            updateSettleAccountStatus(row,type){
                if(!row.id){
                    this.$message.warning('请保存数据');
                    return;
                }
                let form = {id:row.id,isCloseAccount:type,orderNo:row.orderNo,type:'0',feename:row.feename,orderType:'1',settleType:'1'}
                let title ="确定将费用" + row.feename + (type=='0'? "关账吗":"解除关账吗")
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.updateSettleAccountStatus,form).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initBase()
                    })
                }).catch(() => {

                })

            },
            initBase(){
                this.disableMixinMounted = true;
                console.log('自己查询', this.order);
                this.initSettleList();
                this.init();
            },
            changeCellStyle (row, column, rowIndex, columnIndex) {
              // 应收-是否已销账添加背景色
              if(row.row.status && ['1', '2', '9'].indexOf(row.row.status) !== -1) {
                return 'table_green_bg'
              } else {
                return 'table_red_bg'
              }
            },
            init() {
                console.log('费用操作记录', this.$refs['fee']);
                if (this.$refs['fee']) {
                    this.$refs['fee'].initVue();
                }
            },
            handleClearSelection() {
                this.$refs['shiptab'].clearSelection();
            },
            handleClearSelection2() {
                this.$refs['goodtab'].clearSelection();
            },

            //导入产品数据


            getTag(item) {
                return item == '1' ? '已锁定' : '未锁定';
            },
            removeSettle(index) {
                let id = this.form.osOrderSettleList[index].id;
                if ("1" === this.form.osOrderSettleList[index].isCloseAccount) {
                    this.$message.warning('费用已关账不允许操作');
                    return;
                }
                this.$confirm(`确定删除?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    if (id && id.length > 1) {
                        deleteAction(this.url.delete, {id: id})
                            .then(res => {
                                this.$message.success(res.message);
                                this.initSettleList();
                                this.init();
                            }).catch(err => {
                            this.$message.error(res.message);
                        })
                    } else {
                        this.form.osOrderSettleList.splice(index, 1);
                    }
                }).catch(() => {

                })
            },
            copyFee() {
                this.copy = {};
                this.dialogFormVisible = true;
            },
          // 批量快递应付导入
          uploadSectionFile(param) {
            this.btnLoading = true;
            //file就是当前添加的文件
            var fileObj = param.file;
            //FormData 对象
            var form = new FormData();
            //文件对象，key是后台接受的参数名称
            form.append("file", fileObj);
            //执行上传方法
            console.log("执行上传方法8888888888888");
            postAction(this.url.importHySettles, form)
                .then((res) => {
                  //这里做上传之后的操作
                  if (res.success) {
                    this.$message.success(res.message);
                    this.initSettleList();
                    this.init();
                  }

                }).finally(() => {
              this.btnLoading = false;

            })

          },

            copySubmit() {
                this.$refs['copy'].validate(va => {
                    if (va) {
                        this.copy.type = '1';
                        this.copy.oldOrderNo = this.$route.query.orderNo;
                        putAction(this.url.copy + "?orderNo=" + this.copy.orderNo + "&type=1&oldOrderNo=" + this.copy.oldOrderNo)
                            .then(res => {
                                this.$message.success(res.message);
                                this.dialogFormVisible = false;
                                this.initSettleList();
                                this.init();
                            })
                    }
                })
            },
            addFee() {
                this.form.osOrderSettleList.push({
                    settlement: '',
                    feename: '',
                    feenameUs: '',
                    price: '',
                    quantity: '',
                    moneyType: 'CNY',
                    total: '',
                    remark: '',
                    createBy: '',
                    createTime: '',
                    lockTime: '',
                    realMoneyType: '',
                    exchangeRate: '',
                    realPay: '',
                    moneyWay: '',
                    fkCode: '',
                    xzCode: '',
                    fkTime: '',
                    xzTime: '',
                    locker: '0'
                })
            },
            addAttach() {
                this.form.osOrderSettleList.push({
                    settlement: '',
                    feename: '',
                    feenameUs: '',
                    locker: '0',
                    price: '',
                    quantity: '',
                    moneyType: 'CNY',
                    total: '',
                    remark: '',
                    createBy: '',
                    createTime: '',
                    lockTime: '',
                    realMoneyType: '',
                    exchangeRate: '',
                    realPay: '',
                    moneyWay: '',
                    fkCode: '',
                    xzCode: '',
                    fkTime: '',
                    xzTime: '',
                    isAttach: '1'
                })
            },
            getAllPrice(item) {
                console.log('allPrice',(item.quantity * item.price).toFixed(3))
                if (!isNaN((parseFloat(item.quantity))) && !isNaN((parseFloat(item.price)))) {
                    item.total = this.formatDecimal( (item.quantity * item.price).toFixed(3),2 );
                } else {
                    item.total = 0;
                }
            },
            formatDecimal(num, decimal) {
                num = num.toString()
                let index = num.indexOf('.')
                if (index !== -1) {
                    num = num.substring(0, decimal + index + 1)
                } else {
                    num = num.substring(0)
                }
                return parseFloat(num).toFixed(decimal)
            },
            formateTime(val) {
                if (val) {
                    val = val.substring(0, 10);
                    return val;
                } else {
                    return val;
                }
            },
            updateFeeType(row, index) {
              console.log(row,'row')
                let osOrderSettleItem = this.form.osOrderSettleList[index];
                if(row.feename === '运费') {
                  if(this.order.updateFeeWeight) {
                    osOrderSettleItem.quantity = this.order.updateFeeWeight;
                    this.$set(this.form.osOrderSettleList, index, osOrderSettleItem);
                  } else if(row.id){
                    osOrderSettleItem.feename = this.oldFeename;
                    this.$set(this.form.osOrderSettleList, index, osOrderSettleItem);
                    this.$message.warning('没有计费重，请先录入材积！');
                    return;
                  } else {
                    this.form.osOrderSettleList.splice(index,1);
                    this.$message.warning('没有计费重，请先录入材积！');
                    return;
                  }
                } else if(this.oldFeename == '运费') {
                  osOrderSettleItem.quantity = 0;
                  this.$set(this.form.osOrderSettleList, index, osOrderSettleItem);
                }
                if(row.feename === '关税预收') {
                  let yunfeiTotal = this.isYunfeiOne();
                  let otherTotal = this.isOther();
                  // if (yunfeiTotal === null) {
                  //   osOrderSettleItem.feename = this.oldFeename == undefined ? osOrderSettleItem.feename : this.oldFeename;
                  //   osOrderSettleItem.formula = this.oldFormula;
                  //   if(this.$refs['formulaSelect'+index] && this.$refs['formulaSelect'+index].inputVal) {
                  //     this.$refs['formulaSelect'+index].inputVal = osOrderSettleItem.formula;
                  //   }
                  //   this.$set(this.form.osOrderSettleList, index, osOrderSettleItem);
                  //   return;
                  // }
                  osOrderSettleItem.quantity = 1;
                  if(row.formula && row.formula !== undefined) {
                    osOrderSettleItem.price = this.tariffAdvanceCalculation(row.formula, yunfeiTotal, otherTotal) || osOrderSettleItem.price;
                  }
                  this.$set(this.form.osOrderSettleList, index, osOrderSettleItem);
                }
                console.log('费用的map', this.feeTypeMap)
                if (this.feeTypeMap[row.feename]) {
                    this.$set(this.form.osOrderSettleList[index], 'feenameUs', this.feeTypeMap[row.feename]['englishName']);
                }
            },
            // 判断运费是否只保存且列表中只有一条数据，返回yunfeiTotal运费总价
            isYunfeiOne() {
              let feenameYunfeiNumId = 0, feenameYunfeiNum = 0;
              let yunfeiTotal = null;
              this.form.osOrderSettleList.forEach((item,index) => {
                if(item.id) {
                  if(item.feename == '运费') {
                    feenameYunfeiNumId++;
                    yunfeiTotal = item.total;
                  }
                } else if(item.feename == '运费') {
                  feenameYunfeiNum++;
                }
              })
              if(feenameYunfeiNumId == 0) {
                // this.$message.warning('没有保存的运费，请先保存运费');
                yunfeiTotal = null;
              } else if(feenameYunfeiNumId > 1 || feenameYunfeiNum > 0) {
                // this.$message.warning('运费大于1条，请删减');
                yunfeiTotal = null;
              }
              return yunfeiTotal;
            },
            // 判断是否有其他费用，返回其他费用的值
            isOther() {
              let feenameOtherNumId = 0, feenameOtherNum = 0;
              let otherTotal = null;
              this.form.osOrderSettleList.forEach((item,index) => {
                if(item.id) {
                  if(item.feename == '其他费') {
                    feenameOtherNumId++;
                    otherTotal = item.total;
                  }
                } else if(item.feename == '其他费') {
                  feenameOtherNum++;
                }
              })
              if(feenameOtherNumId == 0) {
                // this.$message.warning('没有保存的其他费，请先保存运费');
                otherTotal = null;
              } else if(feenameOtherNumId > 1 || feenameOtherNum > 0) {
                // this.$message.warning('其他费大于1条，请删减');
                otherTotal = 'more';
              }
              return otherTotal;
            },
            // 根据关税预收公式的选择计算费用中文名为关税预收的价格
            tariffAdvanceCalculation(tariffAdvance, yunfeiTotal, otherTotal) {
              let price = tariffAdvance;
              if(tariffAdvance.indexOf('运费') !== -1) {
                if(yunfeiTotal === null) {
                  this.$message.warning('没有保存的运费，请先保存运费');
                  return
                }else if(yunfeiTotal == 'more') {
                  this.$message.warning('运费大于1条，请删减');
                  return
                } else {
                  price = tariffAdvance.replace('运费', yunfeiTotal).replace('申报货值', this.order.price).replace('其他费', otherTotal);
                  console.log(price, eval(price).toFixed(2),'tariffAdvance');
                  return eval(price).toFixed(2);
                }
              } else if(tariffAdvance.indexOf('其他费') !== -1) {
                if(otherTotal === null) {
                  this.$message.warning('没有保存的其他费，请先保存其他费');
                  return
                }else if(otherTotal == 'more') {
                  this.$message.warning('其他费大于1条，请删减');
                  return
                } else {
                  price = tariffAdvance.replace('运费', yunfeiTotal).replace('申报货值', this.order.price).replace('其他费', otherTotal);
                  console.log(price, eval(price).toFixed(2),'tariffAdvance');
                  return eval(price).toFixed(2);
                }
              } else {
                price = tariffAdvance.replace('运费', yunfeiTotal).replace('申报货值', this.order.price).replace('其他费', otherTotal);
                console.log(price, eval(price).toFixed(2),this.order.price,'tariffAdvance');
                return eval(price).toFixed(2);
              }
            },
            updateTariffAdvance(row, index) {
              let osOrderSettleItem = this.form.osOrderSettleList[index];
              let yunfeiTotal = this.isYunfeiOne();
              let otherTotal = this.isOther();
              // if (yunfeiTotal === null) {
              //   osOrderSettleItem.feename = this.oldFeename == undefined ? osOrderSettleItem.feename : this.oldFeename;
              //   osOrderSettleItem.formula = this.oldFormula;
              //   if(this.$refs['formulaSelect'+index] && this.$refs['formulaSelect'+index].inputVal) {
              //     this.$refs['formulaSelect'+index].inputVal = osOrderSettleItem.formula;
              //   }
              //   this.$set(this.form.osOrderSettleList, index, osOrderSettleItem);
              //   return;
              // }
              if(row.formula && row.formula !== undefined) {
                osOrderSettleItem.price = this.tariffAdvanceCalculation(row.formula, yunfeiTotal, otherTotal) || osOrderSettleItem.price;
              }
              this.$set(this.form.osOrderSettleList, index, osOrderSettleItem);
            },
            getOldValue(row) {
                this.oldFeename = row.feename;
                this.oldFormula = row.formula;
            },
            initBdFeeTypeList(row) {
                this.getOldValue(row);
                this.coloading = true;
                if (this.feeTypeList.length <= 0) {
                    this.feeTypeList = [];
                    getBdFeeTypeList('2').then(res => {
                        if (res.success) {
                            this.allFeeTypelist = res.result;
                            this.feeTypeList = this.allFeeTypelist.slice(0, 100);
                            this.feeTypeMap = this.listToMap(this.allFeeTypelist, "chineseName");
                        }
                    }).catch(err => {
                        this.$message.error(err)
                    })
                }
                this.coloading = false;

            },
            getSettle(scope) {
                let val = scope.row.settleId;
                if (this.proMap[val]) {
                    this.$set(scope.row, 'settlement', this.proMap[val]['chinaseName']);
                }
            },
            initProduct() {
                this.cploading = true;
                //获取所有快递供应商
                getAllSupplier("2").then(res => {
                    if (res.success) {
                        this.allSettlelist = res.result;
                        this.prolist = this.allSettlelist.slice(0, 600);
                        this.proMap = this.listToMap(res.result, 'id')
                        this.cploading = false;
                    } else {
                        this.$message.warning('获取结算对象表失败')
                        this.cploading = false;
                    }
                }).catch(err => {
                    this.$message.error(err)
                })

                this.cploading = false;
            },

            handleSelectionChange2(selection) { // 多选
                let arr = []
                this.nameList = []
                this.selectionRows2 = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.nameList.push(selection[i]['feename'])
                    this.selectionRows2.push(selection[i])

                }

                this.selectedRowKeys2 = [...arr]
                console.log('选中的集合', this.selectionRows2);
            },

            getSummaries2(param) {
                //遍历集合
                this.totalgbp = 0;
                this.totalsgd = 0;
                this.totalusd = 0;
                this.totalncd = 0;
                this.totalaud = 0;
                this.totalvnd = 0;
                this.totalnzd = 0;
                this.totalhkd = 0;
                this.totalcad = 0;
                this.totaleur = 0;
                this.totalinr = 0;
                this.totalcny = 0;
                this.totalkrw = 0;
                this.totalnok = 0;
                this.totaljpy = 0;
                this.totaltwd = 0;
                if (this.form.osOrderSettleList) {
                    this.form.osOrderSettleList.forEach(s => {
                      s.formula = '';
                        if (s) {
                            if (s.moneyType == 'GBP') {
                                this.totalgbp += Number(s.total);
                            }
                            if (s.moneyType == 'SGD') {
                                this.totalsgd += Number(s.total);
                            }
                            if (s.moneyType == 'USD') {
                                this.totalusd += Number(s.total);
                            }
                            if (s.moneyType == 'NCD') {
                                this.totalncd += Number(s.total);
                            }
                            if (s.moneyType == 'AUD') {
                                this.totalaud += Number(s.total);
                            }
                            if (s.moneyType == 'VND') {
                                this.totalvnd += Number(s.total);
                            }
                            if (s.moneyType == 'NZD') {
                                this.totalnzd += Number(s.total);
                            }
                            if (s.moneyType == 'HKD') {
                                this.totalhkd += Number(s.total);
                            }
                            if (s.moneyType == 'CAD') {
                                this.totalcad += Number(s.total);
                            }
                            if (s.moneyType == 'EUR') {
                                this.totaleur += Number(s.total);
                            }
                            if (s.moneyType == 'INR') {
                                this.totalinr += Number(s.total);
                            }
                            if (s.moneyType == 'CNY') {
                                this.totalcny += Number(s.total);
                            }
                            if (s.moneyType == 'KRW') {
                                this.totalkrw += Number(s.total);
                            }
                            if (s.moneyType == 'NOK') {
                                this.totalnok += Number(s.total);
                            }
                            if (s.moneyType == 'JPY') {
                                this.totaljpy += Number(s.total);
                            }
                            if (s.moneyType == 'TWD') {
                                this.totaltwd += Number(s.total);
                            }

                        }

                    })
                }
                this.showSumm();
                console.log('展示费用总计', this.ss);

            },
            showSumm() {
                this.ss = "总计: ";
                if (this.totalusd > 0) {
                    this.ss += " USD:" + this.totalusd.toFixed(3);
                }
                if (this.totalcny > 0) {
                    this.ss += " CNY:" + this.totalcny.toFixed(3);
                }
                if (this.totalcad > 0) {
                    this.ss += " CAD:" + this.totalcad.toFixed(3);
                }
                if (this.totalaud > 0) {
                    this.ss += " AUD:" + this.totalaud.toFixed(3);
                }
                if (this.totaleur > 0) {
                    this.ss += " EUR:" + this.totaleur.toFixed(3);
                }
                if (this.totalgbp > 0) {
                    this.ss += " GBP:" + this.totalgbp.toFixed(3);
                }
                if (this.totalhkd > 0) {
                    this.ss += " HKD:" + this.totalhkd.toFixed(3);
                }
                if (this.totalinr > 0) {
                    this.ss += " INR:" + this.totalinr.toFixed(3);
                }
                if (this.totaljpy > 0) {
                    this.ss += " JPY:" + this.totaljpy.toFixed(3);
                }
                if (this.totalkrw > 0) {
                    this.ss += " KRW:" + this.totalkrw.toFixed(3);
                }
                if (this.totalncd > 0) {
                    this.ss += " NCD:" + this.totalncd.toFixed(3);
                }
                if (this.totalnok > 0) {
                    this.ss += " NOK:" + this.totalnok.toFixed(3);
                }
                if (this.totalnzd > 0) {
                    this.ss += " NZD:" + this.totalnzd.toFixed(3);
                }
                if (this.totalsgd > 0) {
                    this.ss += " SGD:" + this.totalsgd.toFixed(3);
                }
                if (this.totaltwd > 0) {
                    this.ss += " TWD:" + this.totaltwd.toFixed(3);
                }
                if (this.totalvnd > 0) {
                    this.ss += " VND:" + this.totalvnd.toFixed(3);
                }

            },
            loadmore() {
                this.formData.pageIndex++;
                this.getMorePortlist(this.formData);
            },
            getMorePortlist(v) {
                let num = ~~this.formData.pageIndex * ~~this.formData.pageSize;
                this.prolist = this.allSettlelist.filter((item, index, arr) => {
                    return index < num;
                });
            },
            loadmore2() {
                this.formData2.pageIndex++;
                this.getMorePortlist2(this.formData2);
            },
            getMorePortlist2(v) {
                let num = ~~this.formData2.pageIndex * ~~this.formData2.pageSize;
                this.feeTypeList = this.allFeeTypelist.filter((item, index, arr) => {
                    return index < num;
                });
            },
            updateBatch() {
                this.$refs['payref'].validate(valid => {
                    if (valid) {
                        //全选了才判断
                        this.form.osOrderSettleList.forEach(s => {
                            s.type = '1';
                            s.orderNo = this.order.orderNo;
                        })
                        putAction(this.url.updateBatch + '?orderNo=' + this.order.orderNo, this.form.osOrderSettleList)
                            .then(res => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.initSettleList();
                                    this.init();
                                }
                            })
                    }
                })

            },
            lock(row) {
                if ("1" === row.isCloseAccount) {
                    this.$message.warning('费用已关账不允许操作');
                    return;
                }
                this.$confirm(`确定锁定${row.feename}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.lock + "?id=" + row.id).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                    })
                }).catch(() => {

                })
            },
            unlock(row) {
                if(['1','2','9'].indexOf(row.status) !== -1) {
                    this.$message.warning('费用在财务流程中不允许解锁');
                    return;
                }
                if ("1" === row.isCloseAccount) {
                    this.$message.warning('费用已关账不允许操作');
                    return;
                }
                if(row.salesmanCommission === '1' && row.isAttach === '0' && row.type ==='0') {
                     this.$message.warning('已发提成订单不允许解锁应收');
                    return;
                }
                if(row.recheckTime && row.isAttach === '0') {
                     this.$message.warning('当前订单进入绩效界面，不允许解锁');
                    return;
                }
                this.$confirm(`确定解锁${row.feename}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.unlock + "?id=" + row.id).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                    })
                }).catch(() => {

                })
            },
            lockBatch() {
                let isAllDelete = true;
                let warningMsg = '';
                this.selectionRows2.forEach(s=>{
                    if("1" === s.isCloseAccount){
                        warningMsg = '勾选存在已关账的费用不允许锁定';
                        isAllDelete = false
                        return false;
                    }
                })
                if(!isAllDelete){
                    this.$message.warning(warningMsg);
                    return
                }
                this.$confirm(`确定批量锁定${this.nameList.join(',')}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.lockBatch + "?ids=" + this.selectedRowKeys2.join(',')).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                    })
                }).catch(() => {

                })
            },
            unlockBatch() {
                let isAllDelete = true;
                let warningMsg = '';
                this.selectionRows2.forEach(s => {
                    if("1" === s.isCloseAccount){
                        warningMsg = '费用已关账不允许解锁';
                        isAllDelete = false
                        return false;
                    }
                })
                if (!isAllDelete) {
                    this.$message.warning(warningMsg);
                    return
                }
                this.$confirm(`确定批量解锁${this.nameList.join(',')}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.unlockBatch,{
                        idList:this.selectedRowKeys2
                    }).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                    })
                }).catch(() => {

                })
            },
            deleteBatch() {
              let isAllDelete = true;
              let warningMsg = '';
              this.selectionRows2.forEach((item, index) => {
                if (['1','2','9'].indexOf(item.status) !== -1) {
                  warningMsg = '费用在财务流程中不允许删除';
                  isAllDelete = false
                  return false;
                } else if (item.locker == '1') {
                  warningMsg = '已锁定的费用不允许删除';
                  isAllDelete = false
                  return false;
                } else if("1" === item.isCloseAccount){
                    warningMsg = '费用已关账不允许解锁';
                    isAllDelete = false
                    return false;
                }
              })
              if(!isAllDelete){
                this.$message.warning(warningMsg);
                return
              }

              this.$confirm(`确定批量删除${this.nameList.join(',')}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys2.join(',')})
                        .then(res => {
                            this.$message.success(res.message);
                            this.initSettleList();
                            this.init();
                        }).catch(err => {
                        this.$message.error(res.message);
                    })
                }).catch(() => {

                })
            },
            lockOrder() {
                this.$confirm(`确定锁定${this.order.orderNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                     postAction(this.url.lockOrder,{
                    orderNo : this.order.orderNo,
                    type:"1"
                     }).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initOrder()
                    })
                }).catch(() => {

                })
            },
            unlockOrder() {
                this.$confirm(`确定解锁${this.order.orderNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                       postAction(this.url.unlockKdOrder,{
                    orderNo : this.order.orderNo,
                    type:"1"
                     }).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initOrder()
                    })
                }).catch(() => {

                })
            },


            handleQuery2() {
                this.goodsloading = true
                let form = {...this.queryForm, ...this.filterForm}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')

                    } else {
                        this.$set(params, 'column', 'createTime')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }
                console.log("查询对象", params)

                getAction(this.url.getSettleList, params).then((res) => {
                    if (res.success) {
                        // const {records, total} = res.result
                        // this.tableData = records || res.result;
                        // console.log("表单数据", this.tableData);
                        // this.form.osOrderSettleList = this.tableData;
                        // this.ipagination && (this.ipagination.total = total || res.result.length);
                        // this.getSummaries2('');
                      const settleList = res.result.list || [];
                      const vo2Map = res.result.vo2Map;
                      console.log("获取vo2Map",vo2Map)
                      const {records, total} = settleList;
                      this.tableData = records || settleList;
                      this.form.osOrderSettleList = this.tableData;
                      this.ipagination && (this.ipagination.total = total || settleList.length);
                      this.getSummaries2('');
                      if (vo2Map) {
                        this.recTotal = vo2Map.recTotal;
                        this.payTotal = vo2Map.payTotal;
                        this.profit = vo2Map.profit;
                      }
                    }
                }).finally(() => {
                    this.goodsloading = false
                })
            },
            handleDel() {

            },


            handleReset() {
                this.queryForm = {}
                this.queryForm.orderNo = this.$route.query.orderNo;
                this.queryForm.type = '1';
                this.ipagination.currentPage = 1
                this.handleQuery2();
            },

            initSettleList() {
                this.queryForm.orderNo = this.$route.query.orderNo;
                this.queryForm.type = '1';
                this.handleQuery2();
            },
            initOrder() {
                let orderNo = this.$route.query.orderNo;
                console.log('订单号', orderNo)
                if (orderNo) {
                    getAction(this.url.getByOrderNo + orderNo)
                        .then(res => {
                            this.order = res.result;
                            console.log('获取的订单数据', this.order);
                            this.initProduct();
                        })
                }
            },
            initButton() {
                const buttons = document.querySelectorAll('.el-button');
                if (this.isUse) {
                    buttons.forEach(b => {
                        b.disabled = this.isUse;
                    })
                }
                console.log('所有的按钮', buttons);
            },
        },
        created() {
            this.disableMixinMounted = true;
            this.initOrder();
            console.log('自己查询', this.order);
            this.initSettleList();
            this.initButton();

        },
      computed:{
        buttonShowList(){
          return {
            'gl':this.$btnIsShow('docpage/modules/RecModule','1','关账'),
            'jcgz':this.$btnIsShow('docpage/modules/RecModule','1','解除关账'),
            'batchUnlockFee':this.$btnIsShow('kdorder/modules/PayModule','0','批量解锁'),
            'SingleUnlockFee':this.$btnIsShow('kdorder/modules/PayModule','1','解锁'),
            '查看结算对象':this.$btnIsShow('kdorder/modules/PayModule','1','查看结算对象'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
