<template>
  <el-card class="box-card">
      <div class="title_tab_text">物流信息</div>
    <!-- <el-card class="box-card"> -->
<!--      <div class="f-p-b-15">-->
<!--        <el-button-->
<!--          type="primary"-->
<!--          icon="el-icon-download"-->
<!--          @click="handleExportXls"-->
<!--          size="small" :disabled="isShow"-->
<!--          >导出</el-button-->
<!--        >-->
<!--        <el-button type="primary" size="medium" @click="syncOrderLogOld">手动同步历史操作记录</el-button>-->
<!--      </div>-->
      <f-table
        v-loading="loading"
        ref="GTable"
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    <!-- </el-card> -->
  </el-card>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction } from "../../../api/manage";

export default {
  name: "ConStatusModule",
  mixins: [QueryMixins],
  components: {},


  data() {
    return {


      columns: [

        {
          prop: "createBy",
          label: "操作人",
            width:120
        },
        {
          prop: "createTime",
          label: "操作时间",
            width:120

        },
        {
          prop: "content",
          label: "状态",
            width:120
        },
          {
              prop: "description",
              label: "物流信息",
          },
      ],
      url: {
        list: "/operation/container/getOperateStatusIpage",
        // syncOrderLogOld: "/interface/ttOrder/syncOsOrderLogOld",
        // exportXlsUrl: "/file/export/excel/downloadOsOrderLogExcel",
      },
      isUsePost:true

    };
  },
  methods: {





    initVue() {
        //获取url里的参数
      this.queryForm.containerId = this.$route.query.id;
      this.handleQuery();
    },
  },


  mounted() {
  },
  created() {
    this.initVue();
  },
};
</script>

<style lang="scss" scoped>
.circle {
  width: 160px;
  height: 84px;
  margin-top: 10px;
  border: 5px solid #c0c4cc;
  border-radius: 0 30% 30% 0/50%;
  border-left: none;
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    width: 36px;
    height: 36px;
    transform: translate(55%, -50%);
    background-color: #fff;
    border-radius: 50%;
    color: #c0c4cc;
    text-align: center;
    font-size: 12px;
    border: 8px solid #ffffff;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-icon-success {
      font-size: 24px;
      color: #cecece;
    }
    .tips {
      display: flex;
      position: absolute;
      right: 30px;
      font-size: 12px;
      color: #c0c4cc;
      width: 80px;
      padding-right: 8px;
      flex-direction: column;
      text-align: center;
      > span:first-child {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &.finish {
    border-color: #409eff;
    .icon {
      .el-icon-success {
        color: #409eff;
      }
      .tips {
        color: #409eff;
      }
    }
  }
}
.custom-steps {
  padding: 40px;
  display: flex;
  .left-steps {
    display: flex !important;
    width: 50px !important;
    /*width: calc(~"100% - 160px");*/
    /*float: left;*/
  }
  .right-steps {
    /*float: right;*/
    margin-left: -140px;
  }
  ::v-deep.el-step__icon {
    /*background: #f6f6f6;*/
  }
  ::v-deep.el-step__icon-inner {
    font-size: 36px;
  }
  ::v-deep.el-step__line {
    background: transparent;
    border-top: 5px solid;
    .el-step__line-inner {
      border-width: 0 !important;
    }
  }
  ::v-deep.el-step {
    position: relative;
    .el-step__head.is-process {
      color: #c0c4cc;
      border-color: #c0c4cc;
    }
    .el-step__title.is-process {
      color: #c0c4cc;
      font-weight: unset;
    }
  }
}
</style>
