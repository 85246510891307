<template>
<div>
    <el-card class="box-card">
    <div class="title_tab_text">物流信息</div>
    <el-table :data="tableData" class="c-th-has-bgcolor" style="width: 100%">
      <el-table-column label="创建时间" width="180px">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" width="">
        <template slot-scope="scope">
          <!-- <span>{{ scope.row.descText }}</span> -->
          <span v-html="scope.row.descText"></span>
          <a style="color: #00a0e9" @click.prevent="goPage(scope.row.orderNo)" v-if="scope.row.showMore">更多单号...</a>
        </template>
      </el-table-column>
      <el-table-column label="创建人" width="200px">
        <template slot-scope="scope">
          <span>{{ scope.row.createBy }}</span>
        </template>
      </el-table-column>
       <el-table-column label="修改人" width="200px" prop="updateBy">
       
      </el-table-column>
       <el-table-column label="修改时间" width="200px" prop="updateTime">
       
      </el-table-column>
       <el-table-column
      fixed="right"
      label="操作"
      width="100">
      <template slot-scope="scope">
        <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
      </template>
    </el-table-column>
    </el-table>
  </el-card>


  
                    <el-dialog title="更新物流信息" :visible.sync="visible" width="40%"
                   :close-on-click-modal="false">
                   <el-form :model="form" :rules="rules" ref="dialogForm">
                    <el-form-item label="物流信息中文" prop="description">
                     <el-input class="textarea_table" v-model="form.description" type="textarea"
                                     ></el-input>
                  
                </el-form-item>
                  <el-form-item label="物流信息英文" prop="descriptionEn">
                     <el-input class="textarea_table" v-model="form.descriptionEn" type="textarea"
                                    ></el-input>
                  
                </el-form-item>
                   </el-form>
                     <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="visible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
      </div>
                   </el-dialog>
</div>

  
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction, postAction  } from "../../../api/manage";

export default {
  name: "StatusModule",
  mixins: [QueryMixins],
  components: {},
  props: {
    shipRes: {
      type: Object,
    },
  },
  data() {
    return {
      disableMixinMounted: true,
      visible: false,
      url: {
        list: "/order/kdstatus/list",
          getTrackInfoList:"/order/kdstatus/getTrackInfoList",
          exportXlsUrl: "/file/export/excel/downloadOsOrderStatusExcel",
          updateStatusContent:'/order/kdstatus/updateStatusContent'
      },
      tableData: [],
      rules:{
          description:[   {required: true, message: '请选择确认到仓时间', trigger: 'change'}],
            descriptionEn:[   {required: true, message: '请选择确认到仓时间', trigger: 'change'}]
      },
      form:{}
    };
  },
  methods: {
    sync17tractDataInStatusList() {
        this.handleQuery();
      getAction(this.url.getTrackInfoList + "?orderNo=" + this.$route.query.orderNo).then((res) => {
        if (!this.shipRes) {
          this.handleQuery();
        }
      });
    },
    handleEdit(row) {
      console.log(row,'row');
      this.form = { ...row }
      this.visible = true;
    },
    initStatusList() {
      getAction(this.url.list, this.queryForm).then((res) => {
        this.tableData = res.result;
        // this.visible = true;
      });
    },
    initVue() {
      this.queryForm.orderNo = this.$route.query.orderNo;
      console.log("created,status初始化....");
      this.handleQuery();
    },

    handleThaw(row) {
      this.$confirm(`确定解冻${row.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.jiedong, { id: row.id }).then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.handleSearch();
        });
      });
    },
    show(item) {
      this.visible = false;
      item.visible = true;
      this.visible = true;
    },
    hide(item) {
      this.visible = false;
      item.visible = false;
      this.visible = true;
    },
    goPage(info) {
      this.$router.push(
        "/publicPage/LogisticsTracking?orderNo=" + this.$route.query.orderNo);
    },
    handleSave() {
 this.$refs['dialogForm'].validate(valid => {
                    if(valid){
                    postAction(this.url.updateStatusContent,this.form).then((res) => {
                    if (res.success) {
                         this.$message.success(res.message);
                         this.visible = false;
                         this.form = {}
                         this.handleQuery()
                    }
                });
                    }
                 })
    }
  },
  created() {
    // this.disableMixinMounted = true;
    this.queryForm.orderNo = this.$route.query.orderNo;
    this.sync17tractDataInStatusList()

    this.initStatusList();
  },
  watch: {
    shipRes(val) {
      if (val) {
        console.log("父亲传递过来status的数据：", val);
        this.tableData = val.osOrderStatusList;
      } else {
        this.handleQuery();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 800;
}
.item {
  display: flex;
  margin-bottom: 10px;
}
.time {
  margin-right: 50px;
}
.bb {
  position: absolute;
  max-width: 800px;
  width: 1000px;
  max-height: 100px;
  bottom: 39px;
  overflow-y: auto;
  border: 1px solid;
  border-radius: 4px;
  background-color: #fff;
}
</style>
