import { render, staticRenderFns } from "./VolumeModule.vue?vue&type=template&id=280de687&scoped=true&"
import script from "./VolumeModule.vue?vue&type=script&lang=js&"
export * from "./VolumeModule.vue?vue&type=script&lang=js&"
import style0 from "./VolumeModule.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./VolumeModule.vue?vue&type=style&index=1&id=280de687&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280de687",
  null
  
)

export default component.exports